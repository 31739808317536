<template>
  <component
    :is="icon"
  />
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'
import type { ComputedRef, Component } from 'vue'

const props = defineProps<{ name: string }>()
const icon: ComputedRef = computed(() => defineAsyncComponent(async (): Promise<Component> => import(`../assets/icons/${props.name}.svg`)))
</script>
